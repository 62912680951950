import React from "react";
import { useSelector } from 'react-redux';

import NotSelected from '../NotSelected';
import Analytics from './Analytics';

const Dashboard = () => {
  const domain = useSelector(state => state.domains.currentDomain );

  return (
    <>
      { domain !== null ?
        <Analytics />
      : <NotSelected title='Please Select Domain.' />}
      
    </>
  );
};

export default Dashboard;
