import Head from "next/head";
import Dashboard from "../components/Dashboard";

const OverviewPage = () => (
  <>
    <Head>
      <title>Home</title>
      <link rel="stylesheet" href="/react-vis.css" />
    </Head>
    <Dashboard />
  </>
);

export default OverviewPage;
