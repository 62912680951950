import axios from "axios";

import {
  BACKLINKS_INFO,
  RESET_BACKLINKS,
  SET_BACKLINKS,
  SET_BACKLINKS_DATA,
  SET_CURRENT_BACKLINK,
  SET_DASHBOARD_LOADING,
  SET_FILTERS,
  SHOW_BACKLINK_MODAL,
  VIEW_TYPE,
} from "./types";

import { toggleDisableButton } from "./configActions";
import { backlinksInfo, backlinksPayload } from "../utils/backlinksPayload";

export const setBacklinksData =
  (domainId, refetchBacklinks = false) =>
  (dispatch) => {
    dispatch(toggleDisableButton(true));
    dispatch(setDashboardLoading(true));
    axios
      .get(`backlinks/${domainId}`)
      .then((response) => {
        const data = response.data;
        dispatch({
          type: SET_BACKLINKS_DATA,
          payload: data,
        });
        dispatch({
          type : BACKLINKS_INFO,
          payload : backlinksPayload(data)
        })
        if (refetchBacklinks) {
          dispatch(setDashboardLoading(true));

          fetch(data.backlinks, {
            withCredentials: false,
          })
            .then(async (response) => {
              const data = await response.json();
              dispatch({
                type : BACKLINKS_INFO,
                payload : backlinksInfo(data.items)
              })
              dispatch(setBacklinks(data.items));
              dispatch(setDashboardLoading(false));
            })
            .catch((err) => dispatch(setDashboardLoading(false)));
        }
      })
      .catch((error) => {
        dispatch(toggleDisableButton(false));
      });
    return Promise.resolve();
  };

export const showBackLinkModal = (show) => (dispatch) => {
  dispatch({ type: SHOW_BACKLINK_MODAL, payload: show });
};

export const setCurrentBacklink = (backlink) => (dispatch) => {
  dispatch({ type: SET_CURRENT_BACKLINK, payload: backlink });
};

export const setBacklinks = (backlinks) => (dispatch) => {
  dispatch({ type: SET_BACKLINKS, payload: backlinks });
};

export const setDashboardLoading = (loading) => (dispatch) => {
  dispatch({ type: SET_DASHBOARD_LOADING, payload: loading });
};
export const setFilters = (filters) => (dispatch) => {
  dispatch({ type: SET_FILTERS, payload: filters });
  return Promise.resolve();
};
export const resetBacklinks = () => (dispatch) => {
  dispatch({ type: RESET_BACKLINKS, payload: [] });
};

export const setBacklinksInfo = (payload) => (dispatch)=> {
  dispatch({type : BACKLINKS_INFO, payload})
}

export const setViewType = (payload) => (dispatch) => {
  dispatch({type : VIEW_TYPE, payload})
}