import { Button, Card, Col, Divider, Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { randomColor } from "../../lib/helpers";
import { calculateBacklinksCount, renderTitle } from "./utils";

const TopReferringDomains = ({ buttonClick, button = true }) => {
  const { backlinks } = useSelector(({ backlinks }) => backlinks);
  const [topReferringDomains, setTopReferringDomains] = useState([]);

  useEffect(() => {
    const data = calculateBacklinksCount(backlinks);
    const firstFive = data.slice(0, 5);
    setTopReferringDomains(firstFive);
  }, [backlinks]);

  return (
    <Card title={renderTitle('Top Referring Domains', 'Referring domains are domains from which the destination website or page receives one or more backlinks.')} style={{height: '100%'}}>
      <>
        <Row style={{ padding: 3, height: 40 }}>
          <Col span={10}>Domain</Col>
          <Col span={8}>Category</Col>
          <Col span={1}></Col>
          <Col span={5} className="text-center">
            Backlinks
          </Col>
        </Row>
        <Divider className="m-0 mb-1" />
      </>
      {topReferringDomains?.map((topReferringDomain) => {
        return (
          <>
            <Row style={{ padding: 3, height: 40 }}>
              <Col span={10}>
                <a href={`https://${topReferringDomain.id}`} target="_blank">
                  {topReferringDomain.name}
                </a>
              </Col>
              <Col span={8}>
                {topReferringDomain?.tags?.map((tag) => {
                  return <Tag color={randomColor()}>{tag} </Tag>;
                })}
              </Col>
              <Col span={1}></Col>
              <Col span={5} className="text-center">
                <span style={{ color: "#1A8FFB" }}>
                  {topReferringDomain.value}
                </span>
              </Col>
            </Row>
            <Divider className="m-0 mb-1" />
          </>
        );
      })}
      {button && <a href="#filters">
        <Button type="primary" onClick={buttonClick} className="mt-4">
          Review Backlinks
        </Button>
      </a>
      }
    </Card>
  );
};

export default TopReferringDomains;
