import { Button, Card, Row } from "antd";
import { ResponsiveBar } from "@nivo/bar";
import { useState } from "react";

const BarChart = ({
  title,
  indexBy,
  dataKeys,
  data,
  scheme = { scheme: "nivo" },
  direction = "vertical",
  label = true,
  legend = true,
  button = null,
  buttonText = null,
  buttonClick = () => {},
}) => {
  const [selectedLegend, setSelectedLegend] = useState(null);

  const handleLegendClick = (legend) => {
    setSelectedLegend(selectedLegend === legend.id ? null : legend.id);
  };

  const filteredData = selectedLegend
    ? data.map((item) => ({
        ...item,
        [selectedLegend == "New" ? "Lost" : "New"]: 0,
      }))
    : data;

  return (
    <Card
      title={title}
      hoverable
      style={{ width: "100%", height : button ? 550 : 500 }}
    >
      {data && data.length > 0 ? (
        <>
        <div style={{height : '400px'}}>
          <ResponsiveBar
            width={window.innerWidth / 2.2}
            data={filteredData}
            keys={dataKeys}
            layout={direction}
            indexBy={indexBy}
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            colors={scheme}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            // maxValue={5000}
            axisTop={null}
            axisRight={null}
            // label={true}
            {...(label == false ? { label: "false" } : null)}
            legends={
              legend
                ? [
                    {
                      dataFrom: "keys",
                      anchor: "top-left",
                      direction: "row",
                      justify: false,
                      translateX: 0,
                      translateY: -50,
                      toggleSerie: true,
                      itemWidth: 60,
                      itemHeight: 20,
                      itemDirection: "left-to-right",
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      onClick: handleLegendClick,
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]
                : []
            }
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
         {button && (
          <a href="#filters">
            <Button type="primary" className="mt-3" onClick={buttonClick}>
              {buttonText}
            </Button>
          </a>

)}
</>
      ) : (
        <div className="no-data-avaliable">No Data Avaliable</div>
        )}
       
    </Card>
  );
};

export default BarChart;
