import moment from "moment";
import { countryCodes } from "../../lib/constants";
import { capitalize } from "../../lib/helpers";
import { calculateBacklinksCountForAnchors, calculateBacklinksCountForTargetPages } from "../../components/Backlinks/utils";

export const backlinksPayload = (data) => {
        const {backlinksSummary , backlinksNewLost} = data
        const tld = [];
        const referingTypes = [];
        const referingAttributes = [];
        const referingCountries = [];
        const referingDomains = [];

        const newlostBackLinks = backlinksNewLost.items.map((stat) => {
            return {
              date: moment.utc(stat.date.split("T")[0]).format("MMM YY"),
              Lost: -stat.lost_backlinks,
              LostColor: "hsl(322, 70%, 50%)",
              New: stat.new_backlinks,
              NewColor: "hsl(278, 70%, 50%)",
            };
          });
          const newlostReferingDomains = backlinksNewLost.items.map((stat) => {
            return {
              date: moment.utc(stat.date.split("T")[0]).format("MMM YY"),
              Lost: -stat.lost_referring_domains,
              LostColor: "hsl(322, 70%, 50%)",
              New: stat.new_referring_domains,
              NewColor: "hsl(278, 70%, 50%)",
            };
          });
  
        Object.entries(backlinksSummary.referring_links_tld).forEach(
          ([key, value]) =>
            tld.push({
              id: key,
              key: key,
              value: value,
              label: key,
            })
        );
        Object.entries(backlinksSummary.referring_links_countries).forEach(
          ([key, value]) =>
            referingCountries.push({
              id: capitalize(countryCodes[key]),
              key: key ? key : "Other",
              value: value,
              label: key ? key : "Other",
            })
        );
        Object.entries(backlinksSummary.referring_links_types).forEach(
          ([key, value]) =>
            referingTypes.push({
              id: capitalize(key),
              key: key,
              value: value,
              label: key,
            })
        );
        Object.entries(backlinksSummary.referring_links_attributes).forEach(
          ([key, value]) =>
            referingAttributes.push({
              id: capitalize(key),
              key: key,
              value: value,
              label: key,
            })
        );
        Object.entries(backlinksSummary.referring_links_platform_types).forEach(
          ([key, value]) =>
            referingDomains.push({
              id: capitalize(key),
              key: key,
              value: value,
              label: key,
            })
        );
        return {
            referringLinkPlatformTypes : referingDomains,
            referringLinkTypes : referingTypes,
            tldDistribution : tld,
            referringLinkAttributes : referingAttributes,
            referringLinkCountries : referingCountries,
            backlinksNewLost : newlostBackLinks,
            referingDomains : newlostReferingDomains
        }
}

export const backlinksInfo = (data) => {
  const anchorsCount = calculateBacklinksCountForAnchors(data)
  const targetPagesCount = calculateBacklinksCountForTargetPages(data)
  return { anchorsCount, targetPagesCount }
}