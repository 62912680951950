import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";

import PieChart from "../Charts/PieChart";
import { setAnalytics } from "../../store/actions/analyticsActions";
import useWindowResize from "../../hooks/useWindowResize";
import TopReferringDomains from "../Backlinks/TopReferringDomains";
import { renderTitle } from "../Backlinks/utils";
import BarChart from "../Backlinks/BarChart";
import { resetBacklinks, setBacklinksData } from "../../store/actions/backlinksActions";
import WeeklyRanksChart from "./WeeklyRanksChart";
import WeeklyByRanksChart from "./WeeklyByRanksChart";

const Analytics = (props) => {
  const dispatcher = useDispatch();
  const isMobile = useWindowResize();
  const domain = useSelector((state) => state.domains.currentDomain);
  const analytics = useSelector((state) => state.analytics.analytics);
  const { backlinksNewLost, backlinksSummary} = useSelector((state) => state.backlinks);
  const [keywordStatusWise, setkeywordStatusWise] = useState(null);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (domain) {
      if (isInitialRender) {
        setIsInitialRender(false);
        if (backlinksSummary != null) {
          return;
        }
      } else {
        dispatcher(resetBacklinks());
      }
      dispatcher(setAnalytics(domain._id));
      dispatcher(setBacklinksData(domain._id, true));
    }
  }, [domain]);

  useEffect(() => {
    if (analytics) {
      setkeywordStatusWise(
        analytics.keywordStatusWise.map((keyword) => {
          return {
            id:
              keyword._id.rankStatusWise === "no_change"
                ? "no impact"
                : keyword._id.rankStatusWise,
            label:
              keyword._id.rankStatusWise === "no_change"
                ? "no impact"
                : keyword._id.rankStatusWise,
            value: keyword.count,
          };
        })
      );
    }
  }, [analytics]);

  return (
    <>
      <Row>
        <Col
          md={12}
          sm={24}
          xs={24}
          style={{ paddingRight: "15px", marginBottom: isMobile ? "16px" : 0 }}
        >
          <PieChart
            data={keywordStatusWise}
            title="Keyword Rank Stats (Today)"
          />
        </Col>
        <Col md={12} sm={24} xs={24} style={{ paddingRight: "15px" }}>
          <WeeklyRanksChart lg={24} md={18}/>
        </Col>
      </Row>
      <br />
      <Row>
        <WeeklyByRanksChart/>
      </Row>
      <br />
      <Row>
        <Col
          md={12}
          sm={24}
          xs={24}
          style={{ paddingRight: "15px", marginBottom: isMobile ? "16px" : 0 }}
        >
          <BarChart
            title={renderTitle(
              "New and Lost Backlinks",
              "Changes in the number of new and lost backlinks linking to an analyzed domain.",
              true
            )}
            indexBy="date"
            dataKeys={["New", "Lost"]}
            data={backlinksNewLost}
            scheme={["#B2DF8A", "#61CDBB"]}
            label={false}
          />
        </Col>
        <Col md={12} sm={24} xs={24} style={{ paddingRight: "15px" }}>
         <TopReferringDomains
            button={false}
            />
        </Col>
      </Row>
    </>
  );
};

export default Analytics;
